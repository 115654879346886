var render = function render(_c,_vm){return _c('vs-dropdown',{staticClass:"cursor-pointer",class:_vm.data.staticClass,attrs:{"vs-trigger-click":""}},[_c('small',{staticClass:"flex cursor-pointer"},[_c('span',[_vm._v("Last 7 days")]),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',{staticClass:"w-32"},[_c('vs-dropdown-item',{on:{"click":function($event){_vm.listeners.timeDurationChanged
          ? {
              timeDurationChanged: _vm.listeners.timeDurationChanged(
                'last-28-days'
              )
            }
          : null}}},[_vm._v("Last 28 days")]),_c('vs-dropdown-item',{on:{"click":function($event){_vm.listeners.timeDurationChanged
          ? {
              timeDurationChanged: _vm.listeners.timeDurationChanged('last-month')
            }
          : null}}},[_vm._v("Last Month")]),_c('vs-dropdown-item',{on:{"click":function($event){_vm.listeners.timeDurationChanged
          ? {
              timeDurationChanged: _vm.listeners.timeDurationChanged('last-year')
            }
          : null}}},[_vm._v("Last Year")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }